import '@material/web/icon/icon.js'
import '@material/web/button/text-button.js'
import '@material/web/button/elevated-button.js'

import { css, html, LitElement } from 'lit'
import { customElement, property, query } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'

import { i18next, localize } from '@operato/i18n'

@customElement('home-page')
export class HomePage extends localize(i18next)(LitElement) {
  static styles = [
    css`
      :host {
        display: block;
        position: relative;
      }

      .signin {
        position: absolute;
        right: 20px;
        top: 10px;
        display: inline-block;
        padding: 12px 24px;
        font-size: 16px;
        font-weight: bold;
        color: var(--md-sys-color-on-tertiary-container);
        background-color: var(--md-sys-color-tertiary-container);
        border: none;
        border-radius: 4px;
        text-align: center;
        text-decoration: none;
        transition:
          background-color 0.3s,
          transform 0.3s;
      }

      .signin:hover {
        color: var(--md-sys-color-tertiary-container);
        background-color: var(--md-sys-color-on-tertiary-container);
        transform: translateY(-2px);
      }

      .signin:active {
        color: var(--md-sys-color-on-tertiary-container);
        background-color: var(--md-sys-color-tertiary-container);
        transform: translateY(0);
      }

      [message] {
        background-color: var(--md-sys-color-secondary-container);
        padding: 60px 50px 0 50px;
        color: var(--md-sys-color-on-secondary-container);
        text-align: center;
        font-size: 20px;
      }

      [message] strong {
        display: block;
        font-size: 2.5rem;
      }

      [message] img {
        width: 800px;
        max-width: 90%;
        display: block;
        margin: auto;
        margin-top: -15px;
      }

      [ticket] {
        display: grid;
        grid-template-columns: 96px 1fr;
        width: 450px;
        margin: auto;
        margin-top: -20px;
        margin-bottom: 60px;
      }

      [brand] {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        overflow: hidden;
        background-color: var(--md-sys-color-primary);
        box-shadow: var(--box-shadow);
        border-right: 3px dotted var(--md-sys-color-secondary-container);
        text-align: center;
      }

      [ticket] [content] {
        border-radius: 12px;
        background-color: var(--md-sys-color-tertiary-container);
        padding: var(--spacing-medium) var(--spacing-large);
        box-shadow: var(--box-shadow);
      }

      fieldset {
        border: none;
        margin: 0;
        padding: 0;
      }

      legend {
        margin: 0;
        padding: var(--spacing-small) 0 var(--spacing-medium) 0;
        font-weight: bold;
        color: var(--legend-color);
        text-transform: uppercase;
      }

      input {
        border: var(--md-sys-color-outline);
        border-radius: var(--border-radius);
        padding: var(--spacing-medium);
        font: var(--input-font);
      }

      input[type='text'] {
        min-width: 190px;
      }

      input:focus {
        outline: none;
      }

      @media screen and (max-width: 480px) {
        [message] {
          padding: 60px 10px 0 10px;
          text-align: center;
          font-size: 14px;
        }
        [message] strong {
          margin-bottom: 15px;
          font-size: 1.6rem;
          line-height: 1.2em;
        }
        [message] img {
          max-width: 100%;
        }

        legend {
          padding: 0;
        }

        [ticket] {
          display: grid;
          grid-template-columns: 0 1fr;
          width: 90%;
          margin: auto;
          margin-top: -15px;
          margin-bottom: 60px;
        }
        [brand] {
          border: 0;
        }
        [content] {
          border: 1px solid var(--md-sys-color-primary);
        }

        fieldset {
          text-align: center;
        }
        input[type='text'] {
          width: calc(100% - 20px);
          margin: var(--spacing-small) 0 var(--spacing-medium) 0;
          text-align: center;
        }
        fieldset md-text-button {
          width: 100%;
        }
      }
    `
  ]

  @property({ type: String, attribute: true }) tagline?: string
  @property({ type: String, attribute: true }) explanation1?: string
  @property({ type: String, attribute: true }) explanation2?: string

  private _applicationMeta?: { icon?: string; title?: string; description?: string }

  render() {
    var { icon, title } = this.applicationMeta

    const tagline = this.tagline || `Join the ${title} Business Program`
    const explanation1 = this.explanation1 || 'Create your free account today.'
    const explanation2 = this.explanation2 || 'Everything you need to go from where you are, to where you want to be.'

    return html`
      <a
        class="signin"
        @click=${e => {
          e.preventDefault()
          window.location.href = '/auth/signin'
        }}
      >
        ${i18next.t('button.sign_in')}</a
      >

      <div message>
        <strong>${tagline}</strong> ${explanation1} <br />
        ${explanation2}
        <img src="/assets/images/invitation.png" />
      </div>

      <div ticket>
        <div brand><img src=${ifDefined(icon)} /></div>
        <div content>
          <form action="/auth/join" accept-charset="utf-8" name="join" method="post">
            <fieldset>
              <legend>${title} invitation ticket</legend>
              <input type="email" name="email" placeholder="Enter your email address" />
              <input type="submit" value="join now" />
            </fieldset>
          </form>
        </div>
      </div>
    `
  }

  @query('input[type=email]') firstInput!: HTMLInputElement

  firstUpdated() {
    setTimeout(() => {
      this.firstInput.focus()
    }, 100)
  }

  get applicationMeta() {
    if (!this._applicationMeta) {
      var iconLink: HTMLLinkElement | null = document.querySelector('link[rel="application-icon"]')
      var titleMeta: HTMLMetaElement | null = document.querySelector('meta[name="application-name"]')
      var descriptionMeta: HTMLMetaElement | null = document.querySelector('meta[name="application-description"]')

      this._applicationMeta = {
        icon: iconLink?.href,
        title: titleMeta ? titleMeta.content : 'Things Factory',
        description: descriptionMeta ? descriptionMeta.content : 'Reimagining Software'
      }
    }

    return this._applicationMeta
  }
}
